@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

path:hover {
    fill: #49d;
}

/* basic positioning */
.legend {
    list-style: none;
    display: flex;
    justify-content: center;
    padding: 0px;
    margin: 0px;
}
.legend li {
    float: left;
    margin-right: 10px;
    padding: 0px;
    margin: 0px;
}
.legend span {
    border: 1px solid #ccc;
    float: left;
    width: 12px;
    height: 12px;
    margin: 2px;
    padding: 0px;
}
/* your colors */
.legend .vert {
    background-color: green;
}
.legend .orange {
    background-color: orange;
}
.legend .rouge {
    background-color: red;
}
